import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "submit", "errors"]

  scrollCheck() {
    const checker = document.getElementById("terms-bottom")

    if (checker.getBoundingClientRect().top <= window.innerHeight) {
      // Remove disabled attribute from terms checkbox
      this.checkboxTarget.removeAttribute("disabled")
    }
  }

  checkTerms() {
    // If terms checkbox is checked, remove disabled attribute from submit button
    if (this.checkboxTarget.checked) {
      this.submitTarget.removeAttribute("disabled")
      this.errorsTarget.classList.add("none")
    } else {
      this.submitTarget.setAttribute("disabled", "disabled")
    }
  }

}
