import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "notice" ]

  close(event) {
  	event.preventDefault();
  	const notice = this.noticeTarget;

    notice.remove();
  }

}
