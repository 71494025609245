import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tier", "course", "message", "link", "tierId", "courseId"]

  create_link(event) {
    const tier_id = this.tierTarget.options[this.tierTarget.selectedIndex].value
    const course_id = this.courseTarget.options[this.courseTarget.selectedIndex].value

    // Make sure a tier and course have been selected
    if ( tier_id != '*' && course_id != '*' ) {
      // Hide the message
      this.messageTarget.classList.add('none')

      // Show the link and populate ID fields
      this.linkTarget.classList.remove('none')
      this.tierIdTarget.innerHTML = tier_id
      this.courseIdTarget.innerHTML = course_id
    } else {
      // Show the message
      this.messageTarget.classList.remove('none')

      // Hide the link
      this.linkTarget.classList.add('none')
    }
    
  }

}
