import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "addButton", "template" ]

  add_new_row(event) {
    event.preventDefault();
    const template = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())

    this.addButtonTarget.insertAdjacentHTML('beforebegin', template)
  }

  remove_row(event) {
    event.preventDefault();
    const parent = event.target.closest('.course-list-fields')

    if ( parent.dataset.newRecord == 'true' ) {
      parent.remove()
    } else {
      parent.querySelector('input[name*="_destroy"]').value = 1
      parent.style.display = 'none'
    }
  }

}
