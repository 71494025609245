import { Controller } from "stimulus"

export default class extends Controller {
	static targets = []

  connect() {

  	// Load Stripe
    const apiKey = document.querySelector('meta[name=stripe_publishable_key]');
    const stripe = Stripe(apiKey.content);

    // Create elements
    const elements = stripe.elements();
    const cardElement = elements.create('card');
    cardElement.mount('#card-element');

    // Process card details
    const cardholderFirstName = document.getElementById('user_first_name');
    const cardholderLastName = document.getElementById('user_last_name');
    const setupForm = document.getElementById('new_user');
    const clientSecret = setupForm.dataset.secret;

    setupForm.addEventListener('submit', function(ev) {
      ev.preventDefault();
      ev.stopImmediatePropagation();
      stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: cardholderFirstName.value + ' ' + cardholderLastName.value,
            },
          },
        }
      ).then(function(result) {
        if (result.error) {
          // Display error.message in your UI.
          console.log(result.error);
        } else {

          // The setup has succeeded.

          // Create hidden fields to store card data
          setPaymentMethod(result.setupIntent.payment_method);

          // Submit the form
          setupForm.submit();
        }
      });
    });

    function setPaymentMethod(payment_method) {
      const hiddenInput = document.getElementById('payment-method');
      hiddenInput.setAttribute('value', payment_method);
    }

  }

}
