import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "userMenu", "userMenuToggle", "mobileNav" ]

  toggleUserMenu(event) {
  	event.preventDefault();
  	const menu = this.userMenuTarget;
  	const toggle = this.userMenuToggleTarget;

  	if ( menu.classList.contains('none') ) {
  		// Open the menu
  		menu.classList.remove('none');
  		toggle.classList.add('active');
  	} else {
  		// Close the menu
  		menu.classList.add('none');
  		toggle.classList.remove('active');
  	}
  }

  toggleMobileMenu(event) {
  	event.preventDefault();
  	const menu = this.mobileNavTarget;

  	if ( menu.classList.contains('active') ) {
  		// Close the menu
  		menu.classList.remove('active');
  	} else {
  		// Open the menu
  		menu.classList.add('active');
  	}
  }

}
